import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { getAlerts, getPressureAlerts } from '../../services/alerts.services';
import {
  AlertsAtom,
  AlertsFilters,
  PressureAlertsAtom,
  PressureAlertsFilters
} from '../../state/alerts.atom';

const usePressureAlerts = (tab: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 15;
  const filtersState = useRecoilValue(PressureAlertsAtom);
  const [filteredData, setFilteredData] =
    useState<PressureAlertsFilters>(filtersState);

  const intervalOptions = ['1', '2', '7'];

  const getData = async () => {
    try {
      const res = await getPressureAlerts(
        currentPage,
        perPage,
        filteredData,
        intervalOptions[tab]
      );

      return {
        meta: res.meta,
        data: res.data?.map((it: any) => ({
          ...it
        })),
        currentHour: res.hour
      };
    } catch (e) {
      toast.error('Erro ao buscar dados');
      throw new Error('Erro ao buscar dados');
    }
  };

  const { data, isFetching, isLoading } = useQuery({
    queryFn: getData,
    queryKey: ['PressureAlerts', currentPage, filteredData, tab],
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false
  });

  const handleFilter = (isClearing?: boolean) => {
    setCurrentPage(1);
    !isClearing
      ? setFilteredData(old => ({
          ...filtersState
        }))
      : setFilteredData({
          field: [],
          status: [],
          criticity: [],
          elevationMethod: [],
          alertType: [],
          station: [],
          fluid: [],
          oilwell: [],
          timeRange: {
            endDate: undefined,
            startDate: undefined
          },
          with_automation: []
        });
  };

  return {
    data: data ?? null,
    isFetching,
    handleFilter,
    isLoading,
    setCurrentPage,
    perPage,
    filteredData,
    setFilteredData,
    currentPage
  };
};

export default usePressureAlerts;
