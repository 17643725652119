import { twMerge } from 'tailwind-merge';

export interface LabelProps {
  name: string;
  id: number | string;
  secondary?: boolean;
  className?: string;
  secondaryClassName?: string;
  noBorder?: boolean;
}

export const Label = ({
  name,
  id,
  secondary,
  className,
  secondaryClassName,
  noBorder
}: LabelProps) => {
  return (
    <div
      className={twMerge(
        `flex w-full ${
          secondary ? 'text-gray text-lg' : 'text-[#3b3b3b] text-xl'
        }`,
        !noBorder ? 'border-t border-[#c1c1c1] pt-3' : '',
        className,
        secondary ? secondaryClassName : ''
      )}
      id={'' + id}
    >
      <span className="font-bold">{name}</span>
    </div>
  );
};
