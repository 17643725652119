import { NestedItemComponentProps } from '@/constants/view/types/structure.type';
import { FaCircleInfo } from 'react-icons/fa6';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

export const ReportHeader = ({
  nestedValues: reportInfo,
  className
}: NestedItemComponentProps) => {
  return (
    <section
      className={twMerge(
        'flex rounded-lg justify-between bg-white px-4 py-3 gap-2 w-full',
        className
      )}
    >
      {reportInfo.map((info, idx) => (
        <div className="flex flex-col" key={info.name + idx}>
          <div className="flex items-center gap-1">
            <h3 className="text-sm font-medium text-gray">{info.name}</h3>
            {info.legend && (
              <div data-tooltip-id={`tooltip-${info.name}-${idx}`}>
                <FaCircleInfo className="text-sm text-primary" />

                <ReactTooltip
                  id={`tooltip-${info.name}-${idx}`}
                  place="bottom-start"
                  variant="light"
                  style={{
                    maxWidth: '250px',
                    textAlign: 'left',
                    backgroundColor: '#F1F1F1'
                  }}
                >
                  {TooltipLegend({ legend: info.legend })}
                </ReactTooltip>
              </div>
            )}
          </div>
          <p
            className={twMerge(
              'text-lg font-semibold text-slate-900',
              idx == 0 ? 'text-primary' : '',
              info.className
            )}
          >
            {info.value}
          </p>
        </div>
      ))}
    </section>
  );
};

const TooltipLegend = ({
  legend
}: {
  legend: { value: string; info: string }[];
}) => {
  return (
    <>
      <div className="text-sm font-bold text-bold text-primary">Legendas</div>
      {legend.map((item, idx) => (
        <div className="flex gap-1" key={item.value + idx}>
          <span className="text-sm font-bold text-bold text-primary">
            {item.value}:
          </span>
          <span className="text-sm text-gray-dark">{item.info}</span>
        </div>
      ))}
    </>
  );
};
