import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import Clock from './Clock';

export enum LateOptions {
  NotLate = 'Não Atrasado',
  Late = 'Atrasado',
  TooLate = 'Muito Atrasado'
}

interface Props {
  id: string;
  late: LateOptions;
}

const LateClock = ({ id, late }: Props) => {
  const lateColor = late === LateOptions.TooLate ? 'red' : 'yellow';
  const isInvisible = late == LateOptions.NotLate ? 'invisible' : '';

  return (
    <>
      <div
        data-tooltip-id={`clock-${id}`}
        data-tooltip-target="tooltip-default"
      >
        <Clock
          className={twMerge(isInvisible, 'w-3.5 h-3.5')}
          color={lateColor}
        />
      </div>

      <ReactTooltip
        id={`clock-${id}`}
        place="top"
        variant="dark"
        style={{ maxWidth: '250px', textAlign: 'center' }}
        content={String(late.toString())}
      />
    </>
  );
};

export default LateClock;
