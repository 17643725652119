import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import FloatingPortal from './FloatingPortal';
import Button from './Button';
import { FaChevronDown } from 'react-icons/fa';

interface Props {
  nameId: string;
  links: { label: string; url: string }[];
  buttonClassName?: string;
  menuClassName?: string;
}

const AlertMenuOptions: React.FC<Props> = ({
  nameId,
  links,
  buttonClassName,
  menuClassName
}) => {
  const [isMenuOptionsVisible, setMenuOptionsVisible] = useState(false);
  const [isMenuInfoVisible, setMenuInfoVisible] = useState(false);
  const toggleTooltip = () => {
    setMenuOptionsVisible(!isMenuOptionsVisible);
  };

  const handleMouseOver = () => {
    setMenuInfoVisible(true);
  };

  const handleMouseOut = () => {
    setMenuInfoVisible(false);
  };

  const closeMenu = () => {
    setMenuOptionsVisible(false);
  };

  const getPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      return {
        top: rect.top + 45,
        left: rect.left
      };
    }

    return { top: 0, left: 0 };
  };

  const getPositionInfo = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      return {
        top: rect.top + 60,
        left: rect.left - 50
      };
    }

    return { top: 0, left: 0 };
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let handler = ({ target }: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current.contains(target as Node)) {
        closeMenu();
      }
    };

    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [buttonRef]);

  useEffect(() => {
    getPosition();
    window.addEventListener('scroll', closeMenu, true);

    return () => {
      window.removeEventListener('scroll', closeMenu, true);
    };
  }, [buttonRef]);

  return (
    <>
      <button
        ref={buttonRef}
        className={twMerge(
          buttonClassName,
          `flex items-center justify-center gap-2
          rounded-3xl 
          bg-primary hover:bg-primary-dark 
          transition-all duration-200
          text-white
          px-5 py-2
          disabled:bg-[#bddaff] disabled:hover:bg-[#bddaff]
          disabled:color-[#1648df]
          disabled:cursor-not-allowed`
        )}
        onClick={toggleTooltip}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <span>Ver no Pi Vision</span>
        <FaChevronDown
          className={`fill-white ml-auto transition-all ${
            isMenuOptionsVisible && 'rotate-180'
          }`}
        />
      </button>

      <FloatingPortal>
        <div
          style={{ ...getPosition(), zIndex: 300 }}
          className={twMerge(
            `
            fixed
            bg-white
            flex flex-col 
            p-2 
            before:content-['']
            rounded-2xl
            z-300
            transition-opacity
            shadow-black
            w-[200px]
            ${
              isMenuOptionsVisible
                ? 'opacity-100 visible'
                : 'opacity-0 invisible'
            }
      `,
            menuClassName
          )}
        >
          {links.map(it => (
            <Link
              key={`${nameId}-${it.label}`}
              to={it.url}
              target="_blank"
              className="flex items-center gap-6 py-2 px-3 w-[120px] hover:bg-btn-light rounded-md group transition-all"
            >
              <span className="text-base text-primary group-hover:text-[#292929] transition-all">
                {it.label}
              </span>
            </Link>
          ))}
        </div>
      </FloatingPortal>

      <FloatingPortal>
        <div
          style={{ ...getPositionInfo(), zIndex: 300 }}
          className={twMerge(
            `
            fixed
            bg-white
            flex flex-col
            p-2 
            shadow-light
            before:content-[''] before:absolute before:-top-2  before:-right-1 before:rotate-45 
            rounded-lg
            z-50
            transition-opacity
            text-center
            w-[280px]
            ${
              isMenuInfoVisible && !isMenuOptionsVisible
                ? 'opacity-100 visible'
                : 'opacity-0 invisible'
            }
      `,
            menuClassName
          )}
        >
          <span className="text-sm transition-all">
            Acesse o histórico de pressões dos poços com automação em outro
            sistema
          </span>
        </div>
      </FloatingPortal>
    </>
  );
};

export default AlertMenuOptions;
