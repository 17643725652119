import { DateValueType } from 'react-tailwindcss-datepicker';
import { atom } from 'recoil';

export interface PiopFilters {
  timeRange: DateValueType;
  timeRangePrioritizedDate: DateValueType;
  station: string[];
  installationLoc: string[];
  equipment: string[];
  order: string;
  note: string;
  statusOrdem: string[];
}

export const PiopReportFiltersAtom = atom<PiopFilters>({
  key: 'PiopReportFilters',
  default: {
    timeRange: {
      startDate: null,
      endDate: null
    },
    timeRangePrioritizedDate: {
      startDate: null,
      endDate: null
    },
    station: [],
    installationLoc: [],
    equipment: [],
    order: '',
    note: '',
    statusOrdem: ['Não Concluída']
  }
});
