import { IoClose } from 'react-icons/io5';
import Modal from '../atoms/Modal';
import Spinner from '../atoms/Spinner';

interface KanbanModalColumnProps {
  children: JSX.Element[];
  title: string;
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: (value: boolean) => void;
  dataLenght: number;
  totalCount: number;
  setLoadMore: () => void;
}

const KanbanModalColumn: React.FC<KanbanModalColumnProps> = ({
  children,
  title,
  isOpen,
  setIsOpen,
  setLoadMore,
  isLoading,
  dataLenght,
  totalCount
}) => {
  return (
    <Modal
      className="max-w-none w-fit relative"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="bg-white rounded-2xl py-3 overflow-hidden">
        <div className="flex items-center justify-between">
          <div className="flex w-full pb-3 pt-2 mx-4 border-gray-200 border-b-[1px] border-[#DFDFDF]">
            <h1 className="text-xs text-primary font-bold">{title}</h1>

            <div className="flex ml-2 items-center justify-center max-h-4 px-[4px] bg-[#758ad5] rounded-full">
              <span className="text-xs text-white">{totalCount}</span>
            </div>

            <IoClose
              size={20}
              className="ml-auto text-primary hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>

        <div className="max-h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full">
          <section className="flex flex-col h-full gap-2 flex-shrink flex-grow basis-auto min-h-0 scrollbar-thin pt-[10px] px-4 pb-4">
            {children}
            {totalCount > dataLenght &&
              (isLoading ? (
                <Spinner />
              ) : (
                <button
                  onClick={setLoadMore}
                  className="text-primary text-xs mt-2 py-1 px-3 bg-btn-light hover:bg-btn-light-2 rounded transition-colors w-min whitespace-nowrap"
                >
                  Ver Mais
                </button>
              ))}
          </section>
        </div>
      </div>
    </Modal>
  );
};

export default KanbanModalColumn;
