import { NestedItemComponentProps } from '@/constants/view/types/structure.type';

const FooterComponent = (props: NestedItemComponentProps) => {
  return (
    <div className="flex w-full gap-2">
      {props.nestedValues.map((item, index) => (
        <section
          className="flex flex-col flex-1 rounded-lg bg-white px-4 py-3 gap-2"
          key={item.name}
        >
          <div className="font-medium text-slate-800">{item.name}</div>
          <span className="text-xl font-bold text-slate-900 uppercase">
            {item.value}
          </span>
        </section>
      ))}
    </div>
  );
};

export default FooterComponent;
