import { RouteObject } from 'react-router-dom';

import RootLayout from '../components/templates/RootLayout';

import { bdoProduction } from '../constants/view/bdos/bdoProduction';
import { bdoWater } from '../constants/view/bdos/bdoWater';

import { Alerts } from '../pages/Alerts';
import { AlertsPressures } from '../pages/Alerts/AlertPressures';
import BdmfMenu from '../pages/BDMF';
import RefreshTimes from '../pages/BDMF/RefreshTimes';
import { BdmfDaily } from '../pages/BDMF/Reports/DailyAllocation';
import BdmfLayout from '../pages/BDMF/Reports/Layout';
import BdmfSummary from '../pages/BDMF/Reports/Summary';
import BdoMenu from '../pages/BDO';
import BdoViewEC from '../pages/BDO/BdoViewEC';
import BdoViewEP from '../pages/BDO/BdoViewEP';
import BdoViewTanks from '../pages/BDO/BdoViewTanks';
import BdoViewTS from '../pages/BDO/BdoViewTS';
import BdoViewUpgn from '../pages/BDO/BdoViewUPGN';
import ErrorComponent from '../pages/ErrorComponent';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Chroma from '../pages/MasterData/Chroma';
import CreateChroma from '../pages/MasterData/Chroma/CreateChroma';
import GasMeter from '../pages/MasterData/GasMeter';
import CreateGasMeter from '../pages/MasterData/GasMeter/CreateGasMeter';
import UpdateGasMeter from '../pages/MasterData/GasMeter/UpdateGasMeter';
import OilFields from '../pages/MasterData/OilFields';
import CreateOilField from '../pages/MasterData/OilFields/CreateOilField';
import UpdateOilField from '../pages/MasterData/OilFields/UpdateOilField';
import OilWells from '../pages/MasterData/OilWells';
import CreateOilWell from '../pages/MasterData/OilWells/CreateOilWell';
import UpdateOilWell from '../pages/MasterData/OilWells/UpdateOilWell';
import OilwellZoneAllocation from '../pages/MasterData/OilwellsZoneAllocation';
import CreateOilwellZonesAllocation from '../pages/MasterData/OilwellsZoneAllocation/CreateZoneAllocation';
import UpdateOilwellZonesAllocation from '../pages/MasterData/OilwellsZoneAllocation/UpdateZoneAllocation';
import PiTags from '../pages/MasterData/PiTags';
import Satellites from '../pages/MasterData/Satellites';
import CreateSatellite from '../pages/MasterData/Satellites/CreateSatellite';
import Tank from '../pages/MasterData/Tank';
import CreateTank from '../pages/MasterData/Tank/CreateTank';
import UpdateMeasuringTank from '../pages/MasterData/Tank/UpdateMeasuringTank';
import Zones from '../pages/MasterData/Zones';
import CreateZones from '../pages/MasterData/Zones/CreateZones';
import UpdateZones from '../pages/MasterData/Zones/UpdateZones';
import PiopMenu from '../pages/PIOP';
import { FinishedPiop } from '../pages/PIOP/Finished';
import { EditTable } from '../pages/PIOP/Report';
import { Summary } from '../pages/PIOP/Summary';
import { WeeklyReportPiop } from '../pages/PIOP/Weekly';
import ProductionMonitoring from '../pages/ProductionMonitoring';
import OilwellLayout from '../pages/TestHistory/Layout';
import WorkPermitWorkflow from '../pages/WorkPermitWorkflow';

const pages: RouteObject[] = [
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorComponent />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/home/tests-workflow',
        async lazy() {
          let { TestsWorkflow } = await import('../pages/TestsWorkflow');
          return { Component: TestsWorkflow };
        }
      },
      {
        path: '/home/scheduled-tests',
        async lazy() {
          let { ScheduledTests } = await import(
            '../pages/TestHistory/ScheduledTests'
          );
          return { Component: ScheduledTests };
        }
      },
      {
        path: '/home/tests/:id',
        async lazy() {
          let { Test } = await import('../pages/TestHistory/Test');
          return { Component: Test };
        }
      },
      {
        path: '/home/tests/follow-up/:id',
        async lazy() {
          let { FollowUpTest } = await import(
            '../pages/TestHistory/FollowUpTest'
          );
          return { Component: FollowUpTest };
        }
      },
      {
        path: '/home/slickline',
        async lazy() {
          let { WorkflowSlickline } = await import(
            '../pages/Slickline/WorkflowSlickline'
          );
          return { Component: WorkflowSlickline };
        }
      },
      {
        path: '/home/work-permit',
        element: <WorkPermitWorkflow />
      },
      {
        path: '/home/tests-workflow/calendar',
        async lazy() {
          let { TestsWorkflowCalendar } = await import(
            '../pages/TestsWorkflow/Calendar'
          );
          return { Component: TestsWorkflowCalendar };
        }
      },
      {
        path: '/home/intervention-report',
        async lazy() {
          let { InterventionReport } = await import(
            '../pages/InterventionReport'
          );
          return { Component: InterventionReport };
        }
      },
      {
        path: '/home/final-drilling-report/:id',
        async lazy() {
          let { FinalDrillingReport } = await import(
            '../pages/BDO/FinalDrillingReport'
          );
          return { Component: FinalDrillingReport };
        }
      },
      {
        path: '/home/slickline/calendar',
        async lazy() {
          let { SlicklineWorkflowCalendar } = await import(
            '../pages/Slickline/Calendar'
          );
          return { Component: SlicklineWorkflowCalendar };
        }
      },
      {
        path: '/home/slickline/dailytasks',
        async lazy() {
          let { DailyTasks } = await import('../pages/Slickline/DailyTasks');
          return { Component: DailyTasks };
        }
      },
      {
        path: '/home/oilwell-info',
        async lazy() {
          let { TestHistory } = await import('../pages/TestHistory');
          return { Component: TestHistory };
        }
      },
      {
        path: '/home/bdo/bdo-history',
        async lazy() {
          let { BDOCalendarView } = await import('../pages/BDO/Calendar');
          return { Component: BDOCalendarView };
        }
      },
      {
        path: '/home/bdo/bdo-pts-history',
        async lazy() {
          let { BDOPtsCalendarView } = await import('../pages/BDO/CalendarPts');
          return { Component: BDOPtsCalendarView };
        }
      },
      {
        path: '/home/bdo/integrity-form',
        async lazy() {
          let { CalendarIntegrityForms } = await import(
            '../pages/BDO/CalendarIntegrityForms'
          );
          return { Component: CalendarIntegrityForms };
        }
      },
      {
        path: '/home/bdo/bdo-reports',
        async lazy() {
          let { BDOReports } = await import('../pages/BDO/Reports');
          return { Component: BDOReports };
        }
      },
      {
        path: '/home/bdo/bdo-view',
        async lazy() {
          let { ViewForm } = await import('../pages/BDO/ViewForm');
          return { Component: ViewForm };
        }
      },
      {
        path: '/home/bdo/slickline',
        async lazy() {
          let { Slicklines } = await import('../pages/BDO/Slicklines');
          return { Component: Slicklines };
        }
      },
      {
        path: '/home/bdo/slickline/consolidated',
        async lazy() {
          let { BdoSlicklines } = await import('../pages/BDO/BdoSlicklines');
          return { Component: BdoSlicklines };
        }
      },
      {
        path: '/home/bdo/slickline/:id',
        async lazy() {
          let { BdoSlickline } = await import('../pages/BDO/BdoSlickline');
          return { Component: BdoSlickline };
        }
      },
      {
        path: '/home/intervention-panel/:id',
        async lazy() {
          let { BdoSptIntervention } = await import(
            '../pages/BDO/DailyInterventionReport'
          );
          return { Component: BdoSptIntervention };
        }
      },
      {
        path: '/home/intervention-panel/register-spt',
        async lazy() {
          let { RegisterSPT } = await import('../pages/SPT/Register');
          return { Component: RegisterSPT };
        }
      },
      {
        path: '/home/intervention-panel/intervention-final-report/:id',
        async lazy() {
          let { FinalSptInterventionReport } = await import(
            '../pages/BDO/FinalInterventionReport'
          );
          return { Component: FinalSptInterventionReport };
        }
      },
      {
        path: '/home/work-permit/:id',
        async lazy() {
          let { WorkPermit } = await import('../pages/WorkPermit');
          return { Component: WorkPermit };
        }
      },
      {
        path: '/home/intervention-panel',
        async lazy() {
          let { InterventionPanel } = await import(
            '../pages/SPT/InterventionPanel'
          );
          return { Component: InterventionPanel };
        }
      },
      {
        path: '/home/bdo/bdo-ts',
        element: <BdoViewTS />
      },
      {
        path: '/home/bdo/production-view',
        element: (
          <BdoViewTanks
            title="Cálculo da Produção Fiscal"
            bdoView={bdoProduction}
          />
        )
      },
      {
        path: '/home/bdo/water-view',
        element: (
          <BdoViewTanks
            title="Tempo de Transbordo dos Tanques de Água"
            bdoView={bdoWater}
          />
        )
      },
      {
        path: '/home/bdo/upgn',
        element: <BdoViewUpgn />
      },
      {
        path: '/home/bdo/bdo-ec',
        element: <BdoViewEC />
      },
      {
        path: '/home/bdo/bdo-ep',
        element: <BdoViewEP />
      },
      {
        path: '/home/oilwell-info/:id',
        element: <OilwellLayout />,
        children: [
          {
            path: '/home/oilwell-info/:id/summary',
            async lazy() {
              let { Summary } = await import('../pages/TestHistory/Summary');
              return { Component: Summary };
            }
          },
          {
            path: '/home/oilwell-info/:id/tests',
            async lazy() {
              let { Tests } = await import('../pages/TestHistory/Tests');
              return { Component: Tests };
            }
          },
          {
            path: '/home/oilwell-info/:id/pressures',
            async lazy() {
              let { Pressure } = await import('../pages/TestHistory/Pressures');
              return { Component: Pressure };
            }
          },
          {
            path: '/home/oilwell-info/:id/opening-closing',
            async lazy() {
              let { OpeningAndClosing } = await import(
                '../pages/TestHistory/OpeningAndClosing'
              );
              return { Component: OpeningAndClosing };
            }
          },
          {
            path: '/home/oilwell-info/:id/rip-control',
            async lazy() {
              let { RipControl } = await import(
                '../pages/TestHistory/RipControl'
              );
              return { Component: RipControl };
            }
          },
          {
            path: '/home/oilwell-info/:id/alerts',
            async lazy() {
              let { OilwellAlerts } = await import(
                '../pages/TestHistory/OilwellAlerts'
              );
              return { Component: OilwellAlerts };
            }
          },
          {
            path: '/home/oilwell-info/:id/flow-rate',
            async lazy() {
              let { FlowRate } = await import('../pages/TestHistory/FlowRate');
              return { Component: FlowRate };
            }
          },
          {
            path: '/home/oilwell-info/:id/equips',
            async lazy() {
              let { Equips } = await import('../pages/TestHistory/Equips');
              return { Component: Equips };
            }
          },
          {
            path: '/home/oilwell-info/:id/drilling',
            async lazy() {
              let { OilwellDrilling } = await import(
                '../pages/TestHistory/OilwellDrilling'
              );
              return { Component: OilwellDrilling };
            }
          },
          {
            path: '/home/oilwell-info/:id/slickline',
            async lazy() {
              let { SlicklineOperations } = await import(
                '../pages/TestHistory/SlicklineOperations'
              );
              return { Component: SlicklineOperations };
            }
          }
        ]
      },
      {
        path: '/home/oilwell-info/:id/rip-control/:issue_id',
        async lazy() {
          let { IntegrityForm } = await import(
            '../pages/TestHistory/IntegrityForm'
          );
          return { Component: IntegrityForm };
        }
      },
      {
        path: '/home',
        element: <ProductionMonitoring />
      },
      {
        path: '/home/bdo',
        element: <BdoMenu />
      },
      {
        path: '/home/piop',
        element: <PiopMenu />
      },
      {
        path: '/home/piop/summary',
        element: <Summary />
      },
      {
        path: '/home/piop/priority',
        element: <EditTable />
      },
      {
        path: '/home/piop/weekly-report',
        element: <WeeklyReportPiop />
      },
      {
        path: '/home/piop/finished-piop',
        element: <FinishedPiop />
      },
      {
        path: '/home/bdmf',
        element: <BdmfMenu />
      },
      {
        path: '/home/bdmf/refresh-times',
        element: <RefreshTimes />
      },
      {
        path: '/home/bdmf/reports',
        element: <BdmfLayout />
      },
      {
        path: '/home/bdmf/daily-allocation',
        element: <BdmfDaily />
      },
      {
        path: '/home/bdmf/summary',
        element: <BdmfSummary />
      },
      {
        path: '/home/alerts',
        element: <Alerts />
      },
      {
        path: '/home/alert-pressures',
        element: <AlertsPressures />
      },
      {
        path: '/home/bdo/production-analysis-2h',
        async lazy() {
          let { Report2H } = await import('../pages/Matrix/Report2H');
          return { Component: Report2H };
        }
      },
      {
        path: '/home/bdo/production-analysis-pts-2h',
        async lazy() {
          let { ReportPts2H } = await import('../pages/Matrix/ReportPts2H');
          return { Component: ReportPts2H };
        }
      },
      {
        path: '/home/bdo/raope',
        async lazy() {
          let { RAOpeReport } = await import('../pages/Matrix/RAOpe');
          return { Component: RAOpeReport };
        }
      },
      {
        path: '/home/bdo/drilling',
        async lazy() {
          let { Drilling } = await import('../pages/BDO/Drilling');
          return { Component: Drilling };
        }
      },
      {
        path: '/home/bdo/drilling/rdp',
        async lazy() {
          let { RDP } = await import('../pages/BDO/Drilling/RDP');
          return { Component: RDP };
        }
      },
      {
        path: '/home/slickline/BM/:outsourced/:slicklineType',
        async lazy() {
          let { BMPage } = await import('../pages/Slickline/BM');
          return { Component: BMPage };
        }
      },
      {
        path: 'oilwells',
        element: <OilWells />
      },
      {
        path: 'oilwells/create',
        element: <CreateOilWell />
      },
      {
        path: 'oilwells/:id',
        element: <UpdateOilWell />
      },
      {
        path: 'chroma',
        element: <Chroma />
      },
      {
        path: 'chroma/create',
        element: <CreateChroma />
      },
      {
        path: 'satellites',
        element: <Satellites />
      },
      {
        path: 'satellites/create',
        element: <CreateSatellite />
      },
      {
        path: 'oilfields',
        element: <OilFields />
      },
      {
        path: 'oilfields/create',
        element: <CreateOilField />
      },
      {
        path: 'oilfields/:id',
        element: <UpdateOilField />
      },
      {
        path: 'oilwell-zone-allocation',
        element: <OilwellZoneAllocation />
      },
      {
        path: 'oilwell-zone-allocation/create',
        element: <CreateOilwellZonesAllocation />
      },
      {
        path: 'oilwell-zone-allocation/:id',
        element: <UpdateOilwellZonesAllocation />
      },
      {
        path: 'zones',
        element: <Zones />
      },
      {
        path: 'zones/create',
        element: <CreateZones />
      },
      {
        path: 'zones/:id',
        element: <UpdateZones />
      },
      {
        path: 'zones',
        element: <Zones />
      },
      {
        path: 'gas-meter',
        element: <GasMeter />
      },
      {
        path: 'gas-meter/create',
        element: <CreateGasMeter />
      },
      {
        path: 'gas-meter/:id',
        element: <UpdateGasMeter />
      },
      {
        path: 'tank',
        element: <Tank />
      },
      {
        path: 'tank/create',
        element: <CreateTank />
      },
      {
        path: 'tank/:id',
        element: <UpdateMeasuringTank />
      },
      {
        path: 'pi-tags',
        element: <PiTags />
      }
    ]
  },
  {
    path: 'login',
    element: <Login />
  }
];

export default pages;
