import { addHours } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { useController, useFormContext } from 'react-hook-form';
import { LuRefreshCcw } from 'react-icons/lu';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SquareButton from '../SquareButton';

interface DatepickerProps {
  name: string;
  keyName: string;
  dateFormat: string;
  value: string;
  suggestedPrioritizedDate: string;
  disabled: boolean;
  handleScroll?: (event: Event) => boolean;
}

export const DatepickerCell = ({
  name,
  keyName,
  dateFormat,
  value,
  suggestedPrioritizedDate,
  disabled,
  handleScroll
}: DatepickerProps) => {
  const { control, setValue } = useFormContext();

  const formatDateToDatepicker = (date: any) => {
    return date ? addHours(new Date(date), 3) : null;
  };

  const [currentValue, setCurrentValue] = useState<any>(
    value ? addHours(new Date(value), 3) : null
  );

  const { field } = useController({
    control,
    name
  });

  const [suggestedDate, setSuggestedDate] = useState<any>(
    suggestedPrioritizedDate
      ? addHours(new Date(suggestedPrioritizedDate), 3)
      : null
  );

  const [editedValue, setEditedValue] = useState(false);

  const handleDateChange = (e: any) => {
    const newDate = formatDateToDatepicker(e);

    setValue(name, newDate);
    setCurrentValue(newDate);
    checkIfFieldHasChanged(newDate);
  };

  const checkIfFieldHasChanged = (newCurrentDate: any) => {
    const currentStartDate =
      newCurrentDate instanceof Date ? newCurrentDate?.toDateString() : null;
    const currentSuggestedDate =
      suggestedDate instanceof Date ? suggestedDate?.toDateString() : null;
    const isDifferent =
      currentStartDate != null
        ? currentStartDate != currentSuggestedDate
        : false;

    isDifferent ? setEditedValue(true) : setEditedValue(false);
  };

  useEffect(() => {
    if (value) {
      checkIfFieldHasChanged(formatDateToDatepicker(value));
      setValue(name, formatDateToDatepicker(value));
      setCurrentValue(formatDateToDatepicker(value));
      setSuggestedDate(formatDateToDatepicker(suggestedPrioritizedDate));
    } else {
      checkIfFieldHasChanged(formatDateToDatepicker(suggestedPrioritizedDate));
      setValue(name, formatDateToDatepicker(suggestedPrioritizedDate));
      setCurrentValue(formatDateToDatepicker(suggestedPrioritizedDate));
      setSuggestedDate(formatDateToDatepicker(suggestedPrioritizedDate));
    }
  }, [value]);

  return (
    <>
      <div className="flex flex-row items-center">
        <DatePicker
          name={name}
          key={keyName}
          onChange={date => handleDateChange(date)}
          value={currentValue}
          selected={currentValue}
          closeOnScroll={handleScroll}
          className="h-[36px] pl-2 pr-10 max-w-[150px] border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 focus:ring-sky-500/20 z-0"
          locale={ptBR}
          disabled={disabled}
          popperClassName="z-50"
          dateFormat={dateFormat}
          autoComplete={'off'}
        />

        {editedValue && (
          <>
            <div
              data-tooltip-id={`alert-${name}`}
              data-tooltip-target="tooltip-default"
              className="ml-2"
            >
              <SquareButton
                icon={<LuRefreshCcw color="blue" size={16} />}
                className="rounded-[3px] w-[28px] h-[29px] text-[8px] bg-[#D7DFFF] hover:bg-[#5e65f2] text-primary cursor-default"
              />
            </div>

            <ReactTooltip
              id={`alert-${name}`}
              place="bottom"
              variant="light"
              className="shadow-md bg-white"
              style={{
                maxWidth: '250px',
                textAlign: 'center',
                zIndex: 99,
                fontSize: 16
              }}
              content={'Dados atualizados'}
            />
          </>
        )}
      </div>
    </>
  );
};
