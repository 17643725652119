import { grid2array } from '../customFields';

export const sumValues = (key: string, data: any[]): number =>
  data.reduce((sum, element) => {
    const value = getNestedValue(element, key);
    return Number.isNaN(Number(value)) ? sum : sum + Number(value);
  }, 0);

export const mean2hValues = (key: string, data: any[]): number =>
  sumValues(key, data) / 12;

export const averageValues = (key: string, data: any[]): number =>
  data.reduce((sum, element) => {
    const value = getNestedValue(element, key);
    return Number.isNaN(Number(value)) ? sum : sum + Number(value);
  }, 0) / data.length;

export const extrapolateValues = (key: string, data: any[]): number =>
  averageValues(key, data) * 12;

export const flattenObject = (
  obj: Record<string, any>,
  prefix: string = ''
): { [key: string]: any } => {
  return Object.keys(obj).reduce((acc: any, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
};

export const getNestedValue = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, key) => acc && acc[key], obj);
};

export const getGridNestedValue = (obj: any, path: string) => {
  return grid2array(getNestedValue(obj, path)?.replace(/=>/g, ':') || '{}');
};

export const containsKey = (obj: any, path: string): any => {
  let current = obj;
  for (const key of path.split('.')) {
    if (!(key in current)) {
      return false;
    }
    current = current[key];
  }

  return true;
};

export const setNestedValue = (obj: any, path: string, value: any): void => {
  const keys = path.split('.');
  const lastKey = keys.pop();

  let current = obj;
  for (const key of keys) {
    if (!(key in current)) {
      current[key] = {};
    }
    current = current[key];
  }

  if (lastKey && current) {
    current[lastKey] = value;
  }
};

export const applyOnNestedValue = (
  data: { [id: string | number]: any },
  id: string,
  f: (x: any) => any
) => {
  const value = f(getNestedValue(data, id));
  setNestedValue(data, id, value);
};

export const makeAccumulatedTableRows = (
  line_label: string,
  table_object: any[],
  ignore_headers: any,
  accumulation_function: Function,
  label_key?: string
) => {
  if (!table_object || table_object.length === 0) return {};

  // Flatten the first object to get all possible keys
  const flattenedObject = flattenObject(table_object[0]);
  const keys = Object.keys(flattenedObject);

  const result: { [key: string]: any } = {
    [label_key ?? 'hour']: line_label
  };

  for (const key of keys) {
    if (!ignore_headers.includes(key) && key !== (label_key ?? 'hour')) {
      const accumulatedValue = accumulation_function(key, table_object);
      setNestedValue(result, key, accumulatedValue);
    }
  }

  return result;
};

export const infoExtraRows = (
  data: any[],
  keys_to_ignore_mean?: any[],
  keys_to_ignore_extrapolated?: any[],
  keys_to_ignore_total?: any[]
) => {
  const keys_to_ignore = ['var'];

  return data && data?.length > 0
    ? [
        makeAccumulatedTableRows(
          'Média',
          data,
          keys_to_ignore_mean ?? keys_to_ignore,
          averageValues
        ),
        makeAccumulatedTableRows(
          'Extrapolado',
          data,
          keys_to_ignore_extrapolated ?? keys_to_ignore,
          extrapolateValues
        ),
        makeAccumulatedTableRows(
          'Total',
          data,
          keys_to_ignore_total ?? keys_to_ignore,
          sumValues
        )
      ].map(it => ({
        ...it,
        isSummary: true
      }))
    : [];
};
