import { equipment } from '../arrays/equipment';
import { objectify } from './WorflowTests';

export const PriorityPageFilter = [
  // {
  //   key: 'timeRange',
  //   name: 'Data Base Início',
  //   values: []
  // },
  {
    key: 'statusOrdem',
    name: 'Status Ordem',
    values: ['Não Concluída', 'Pendente', 'Concluída'].map(objectify)
  },
  {
    key: 'station',
    name: 'Estação',
    values: [
      'FURADO - CAMPO',
      'FURADO - ESTAÇÃO',
      'FURADO - O&M COMPRESSÃO',
      'FURADO - SUBESTAÇÃO',
      'PILAR - CAMPO',
      'PILAR - ESTAÇÃO',
      'PILAR - FLUIDOS',
      'PILAR - LABORATÓRIO',
      'PILAR - O&M COMPRESSÃO',
      'PILAR - P16',
      'PILAR - SUBESTAÇÃO',
      'UPGN'
    ]
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  },
  {
    key: 'equipment',
    name: 'Equipamentos',
    values: equipment.sort((a, b) => a.localeCompare(b)).map(objectify)
  }
  // {
  //   key: 'priority',
  //   name: 'Prioridade',
  //   values: ['Não Priorizado', 'Alta', 'Média', 'Baixa'].map(objectify)
  // }
];
