import { RecoilState } from 'recoil';
import { useParams } from '../../hooks/Common/useParams';
import DatepickerSearchParams from './DatepickerSearchParams';
import SideFilter from './SideFilter';
import { DummyAtom } from '../../state/dummy.atom';

type SideFilterSearchParamsProps<A extends object> = {
  atom?: RecoilState<A>;
  filters?: {
    key: string;
    name: string;
    values: {
      value: any;
      label: string;
    }[];
    asyncFn?: any;
    type?: string;
  }[];
  filterValues?: A;
  setUsedFilters?: React.Dispatch<React.SetStateAction<any>>;
  dateSearchParams: { name: string; value: string | null; keyValue: string }[];
};

export function SideFilterSearchParams<A extends object>({
  atom,
  filters = [],
  filterValues = {} as A,
  setUsedFilters = () => {},
  dateSearchParams
}: SideFilterSearchParamsProps<A>) {
  const { setParam, params } = useParams();

  const handleFilter = (isClearing?: boolean) => {
    if (isClearing) {
      const searchParamsMap = dateSearchParams.reduce(
        (acc, dateSearchParam) => {
          return { ...acc, [dateSearchParam.keyValue]: null };
        },
        {}
      );

      const deafultValues = Object.keys(filterValues).reduce((acc, key) => {
        return { ...acc, [key]: [] };
      }, searchParamsMap);

      setParam(new URLSearchParams());
      setUsedFilters(deafultValues);
    } else {
      const filteredParams = dateSearchParams.reduce((acc, dateSearchParam) => {
        return { ...acc, [dateSearchParam.keyValue]: dateSearchParam.value };
      }, filterValues);

      setUsedFilters(filteredParams);
    }
  };

  return (
    <>
      <SideFilter
        atom={atom || DummyAtom}
        applyChanges={handleFilter}
        filters={filters}
      >
        {dateSearchParams.map(props => (
          <DatepickerSearchParams {...props} key={props.keyValue} />
        ))}
      </SideFilter>
    </>
  );
}
