import EmptyMessage from './EmptyMessage';

const EMPTY_MESSAGE = 'Sem dados disponíveis';

const TextComponent = (props: { value?: string; label?: string }) => {
  return (
    <section className="flex flex-col flex-1 rounded-lg bg-white px-4 py-3 gap-2 whitespace-pre-wrap">
      <span>
        {props.label ? (
          <span className="font-bold text-lg">{props.label}: </span>
        ) : null}
        {props.value ? (
          props.value
        ) : (
          <EmptyMessage
            message={EMPTY_MESSAGE}
            className="min-h-[2rem] w-min"
          />
        )}
      </span>
    </section>
  );
};

export default TextComponent;
