import {
  CardsFontVariant,
  CardsVariant,
  SummaryBigCardsItem
} from '@/components/molecules/SummaryBigCards';
import { getNestedValue } from '@/utils/table';
import {
  formatDateTime,
  formatDateTimeISO,
  numberFormatter,
  numberFormatter2
} from '@/utils/utils';
import { addHours, format } from 'date-fns';
import { Column } from 'react-table';

export type PrimitiveTypes =
  | 'string'
  | 'number'
  | 'shortNumber'
  | 'stringArray'
  | 'bullet'
  | 'date'
  | 'time'
  | 'datetime'
  | 'datetimeiso'
  | 'shortDate';

export type NestedStructureItem = {
  id: string | number;
  name?: string;
  primitiveType?: PrimitiveTypes;
  legend?: { value: string; info: string }[];
  className?: string;
}[];

export type SummaryBigCardsStructure = {
  name: string;
  structure: NestedStructureItem;
  priority?: 'high' | 'medium' | 'low';
  variant?: CardsVariant;
  font?: CardsFontVariant;
  textColor?: string;
  bgColor?: string;
  className?: string;
}[];

export type StructureItem =
  | {
      type: 'label';
      id: number | string;
      name: string;
      size?: 'h1' | 'h2';
      condition?: number | string;
      reverseCondition?: boolean;
      noBorder?: boolean;
    }
  | {
      type: 'grid';
      id: number | string;
      columns?: Column[];
      name?: string;
      order?: (number | string)[];
      condition?: number | string;
      reverseCondition?: boolean;
      value?: string;
      tableContainerClassName?: string;
      containerClassName?: string;
      headerClassName?: string;
      bodyClassName?: string;
      noBorder?: boolean;
      aggregatedColumns?: {
        groups?: { id: string; className: string }[];
        columns: { id: string; group?: string; className: string }[];
      };
    }
  | {
      type: 'text';
      id: number | string;
      name?: string;
      condition?: number | string;
      value?: string;
      reverseCondition?: boolean;
    }
  | {
      type: 'report-header';
      id: number | string;
      structure: NestedStructureItem;
      name?: string;
      condition?: number | string;
      reverseCondition?: boolean;
      className?: string;
    }
  | {
      type: 'footer';
      id: number | string;
      structure: NestedStructureItem;
      name?: string;
      condition?: number | string;
      reverseCondition?: boolean;
      className?: string;
    }
  | {
      type: 'summary-cards';
      id: number | string;
      structure?: SummaryBigCardsStructure;
      name?: string;
      value?: SummaryBigCardsItem[];
      condition?: number | string;
      reverseCondition?: boolean;
      className?: string;
    }
  | {
      type: 'raw-table';
      id: number | string;
      columns: Column[];
      tableClassName?: string;
      headerClassName?: string;
      bodyClassName?: string;
      rowClassName?: string;
      name?: string;
      condition?: number | string;
      reverseCondition?: boolean;
      className?: string;
    }
  | {
      type:
        | 'input'
        | 'date'
        | 'number'
        | 'custom'
        | 'stringArray'
        | 'time'
        | 'bullet'; // TODO: remover stringarray
      id: number | string;
      name: string;
      value?: string;
      field?: string;
      condition?: number | string;
      reverseCondition?: boolean;
      fullWidth?: boolean;
    }
  | {
      type: 'link';
      condition?: number | string;
      reverseCondition?: boolean;
      id: number | string;
      name: string;
    };

export interface NestedItemComponentProps {
  nestedValues: {
    name: string;
    value: string;
    legend?: { value: string; info: string }[];
    className?: string;
  }[];
  className?: string;
}

export const convertToNameNValue = (
  structure: NestedStructureItem,
  data: { [id: number | string]: any }
) => {
  return structure.map(item => {
    const dataValue = data && getNestedValue(data, item.id.toString());
    const value = item.primitiveType
      ? formatValue(item.primitiveType, dataValue)
      : dataValue;

    return {
      name: item.name || '',
      value,
      legend: item.legend,
      className: item.className
    };
  });
};

export const convertToSummaryBigCards = (
  structure: SummaryBigCardsStructure,
  data: { [id: number | string]: any }
) => {
  return structure.map(item => {
    const values = item.structure.map(subitem => {
      const dataValue = data && getNestedValue(data, subitem.id.toString());
      const value: string = subitem.primitiveType
        ? formatValue(subitem.primitiveType, dataValue)
        : dataValue;

      return { label: subitem.name, value: value };
    });

    const cardItem: SummaryBigCardsItem = {
      title: item.name,
      value: values,
      priority: item.priority,
      variant: item.variant,
      font: item.font,
      textColor: item.textColor || '',
      bgColor: item.bgColor || '',
      className: item.className
    };

    return cardItem;
  });
};

export const formatItem = (item: StructureItem, dataValue: any) => {
  return formatValue(item.type, dataValue);
};

const applyFormat = (formatter: Intl.NumberFormat, value: string) => {
  if (value === null || value === undefined || value === '') {
    return '';
  }

  const number = Number(value);
  if (isNaN(number)) {
    return '';
  }

  return formatter.format(number);
};

export const formatValue = (primitiveType: string, dataValue: any) => {
  switch (primitiveType) {
    case 'shortNumber':
      return applyFormat(numberFormatter2(), dataValue);
    case 'number':
      return applyFormat(numberFormatter, dataValue);
    case 'stringArray':
      return dataValue.join(', ');
    case 'bullet':
      return dataValue.map((it: string) => '• ' + it).join('\n');
    case 'date':
      return dataValue && format(addHours(dataValue, 3), 'dd/MM/yyyy'); // TODO convert all to use ParseISO
    case 'time':
      return format(addHours(dataValue, 3), 'HH:mm');
    case 'datetimeiso':
      return formatDateTimeISO(dataValue);
    case 'datetime':
      return formatDateTime(dataValue);
    case 'shortDate':
      return dataValue && format(addHours(dataValue, 3), 'dd/MM/yy');
    default:
      return dataValue ?? '';
  }
};

export type StructureType = StructureItem[];
