import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LuRefreshCcw } from 'react-icons/lu';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import SquareButton from '../SquareButton';

interface TextareaProps {
  name: string;
  keyName: string;
  value: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  rows: number;
}

export const TextareaCell = ({
  name,
  keyName,
  className,
  disabled = false,
  value,
  rows
}: TextareaProps) => {
  const { register, setValue } = useFormContext();

  const [originalValue, setOriginalValue] = useState<string | undefined>(value);
  const [editedValue, setEditedValue] = useState(false);

  const handleTextChange = ({ target }: any) => {
    const { value } = target;
    setValue(name, value);
    checkIfFieldHasChanged(value);
  };

  const checkIfFieldHasChanged = (newValue: any) => {
    if (newValue != null && newValue != '' && newValue.length > 0) {
      setEditedValue(true);
    } else {
      setEditedValue(false);
    }
  };

  useEffect(() => {
    setOriginalValue(value);
    checkIfFieldHasChanged(value);
  }, [value]);

  useEffect(() => {
    setValue(name, value);
    setOriginalValue(value);
    checkIfFieldHasChanged(value);
  }, [value]);

  return (
    <span
      className={twMerge(
        'text-[#191919] text-xs whitespace-nowrap w-full h-full relative flex-1'
      )}
    >
      <div className="flex flex-row items-center">
        <textarea
          {...register(name)}
          onChange={e => handleTextChange(e)}
          defaultValue={originalValue}
          disabled={disabled}
          className={twMerge(
            'flex flex-col h-8 gap-1 rounded bg-white border border-[#b3b3b3] active:border-[#cbcbcb] w-[300px]',
            className ?? ''
          )}
          rows={rows}
          key={keyName}
        ></textarea>

        {editedValue && (
          <>
            <div
              data-tooltip-id={`alert-${name}`}
              data-tooltip-target="tooltip-default"
              className="ml-2"
            >
              <SquareButton
                icon={<LuRefreshCcw color="blue" size={16} />}
                className="rounded-[3px] w-[28px] h-[29px] text-[8px] bg-[#D7DFFF] text-primary cursor-default"
              />
            </div>

            <ReactTooltip
              id={`alert-${name}`}
              place="bottom"
              variant="light"
              className="shadow-xl"
              style={{
                maxWidth: '250px',
                textAlign: 'center',
                zIndex: 99,
                fontSize: 16
              }}
              content={'Dados atualizados'}
            />
          </>
        )}
      </div>
    </span>
  );
};
