import { Column } from 'react-table';
import { Header } from '../../components/atoms/table/Header';
import { DefaultCell } from '../../components/atoms/table/DefaultCell';
import { DateHourCell } from '../../components/atoms/table/HourCell';

const equipActivityCols: Column[] = [
  {
    Header: () => <Header text={'ID'} id="id" />,
    accessor: 'id_BDO',
    Cell: ({ value }: any) => {
      return (
        <a
          className="text-primary text-xs font-bold"
          href={`${import.meta.env.VITE_REDMINE_URL}/issues/${value}`}
          target="_blank"
        >
          {value}
        </a>
      );
    },
    width: '6%'
  },
  {
    Header: <Header text={'Data'} id="Data" />,
    accessor: 'Data',
    Cell: DefaultCell,
    width: '6%'
  },
  {
    Header: <Header text={'Hora Início'} id="Hora_Início" />,
    accessor: 'Hora_Início',
    Cell: DateHourCell,
    width: '6%'
  },
  {
    Header: <Header text={'Hora Fim'} id="Hora_Fim" />,
    accessor: 'Hora_Fim',
    Cell: DateHourCell,
    width: '6%'
  },
  {
    Header: <Header text={'Duração'} id="Duração" />,
    accessor: 'Duração',
    Cell: DefaultCell,
    width: '6%'
  },
  {
    Header: <Header text={'Grupo de Atividade'} id="Grupo de Atividade" />,
    accessor: 'Grupo de Atividade',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Atividade'} id="Atividade" />,
    accessor: 'Atividade',
    Cell: DefaultCell,
    width: '10%'
  },
  {
    Header: (
      <Header
        text={'Classificação da Atividade'}
        id="Classificação da Atividade"
      />
    ),
    accessor: 'Classificação da Atividade',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Solicitante'} id="Solicitante" />,
    accessor: 'Solicitante',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Responsável 1'} id="Responsável 1" />,
    accessor: 'Responsável 1',
    Cell: DefaultCell,
    width: '8%'
  },
  {
    Header: <Header text={'Responsável 2'} id="Responsável 2" />,
    accessor: 'Responsável 2',
    Cell: DefaultCell,
    width: '8%'
  }
];

export default equipActivityCols;
