import { atom } from 'recoil';

type InterventionPanelFilterType = {
  oilwell: string[];
  interventionStatus: string[];
};

export type InterventionPanelFilterAtomType = InterventionPanelFilterType & {
  startDate: string | null;
};

export const interventionPanelFilterAtom = atom<InterventionPanelFilterType>({
  key: 'interventionPanelFilterAtom',
  default: {
    oilwell: [],
    interventionStatus: []
  }
});
