import { equipment } from '../arrays/equipment';
import { objectify } from './WorflowTests';

export const PiopFilters = [
  {
    key: 'station',
    name: 'Estação',
    values: [
      'FURADO - CAMPO',
      'FURADO - ESTAÇÃO',
      'FURADO - O&M COMPRESSÃO',
      'FURADO - SUBESTAÇÃO',
      'PILAR - CAMPO',
      'PILAR - ESTAÇÃO',
      'PILAR - FLUIDOS',
      'PILAR - LABORATÓRIO',
      'PILAR - O&M COMPRESSÃO',
      'PILAR - P16',
      'PILAR - SUBESTAÇÃO',
      'UPGN'
    ]
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  },
  {
    key: 'equipment',
    name: 'Equipamentos',
    values: equipment.sort((a, b) => a.localeCompare(b)).map(objectify)
  },
  {
    key: 'priority',
    name: 'Prioridade',

    values: ['Alta', 'Média', 'Baixa'].map(objectify)
  },
  {
    key: 'discipline',
    name: 'Disciplina',
    values: [
      'ANDAIME',
      'AUTOMACAO',
      'C&M',
      'CALDEIRARIA',
      'CIP',
      'CM - PROJETO ESPECIAIS',
      'ELETRICA',
      'EMSERCOL - CALDEIRARIA',
      'EMSERCOL - CANTEIRO',
      'EMSERCOL - ELETRICA',
      'EMSERCOL - INSPEÇÃO',
      'EMSERCOL - MI',
      'EMSERCOL - INSTRUMENTACAO',
      'EMSERCOL - PINTURA',
      'FABRICAÇÃO  - PILAR',
      'FABRICAÇÃO - FURADO',
      'INP',
      'INSPECAO',
      'INSPEÇÃO',
      'INSPECAO - CALIBRAÇÃO',
      'INSPECAO - QUALIDADE',
      'INSPECAO - SPIE',
      'INSTRUMENTACAO',
      'JATEAMENTO PIPE SHOP',
      'LAVAGEM INDUSTRIAL',
      'L&H',
      'MAN. CIVIL',
      'MAN. PREDIAL',
      'MAN. SERVIÇOS GERAIS',
      'MANUT. LOC E FAIXA',
      'MECANICA',
      'MECANICA CONFIABILIDADE',
      'MECANICA DE POÇOS',
      'MECANICA LUBRIN',
      'MEDIÇÃO FISCAL',
      'O&M - INSTRUMENTACAO',
      'O&M - MECANICA',
      'OPERACAO',
      'PARADA',
      'PINTURA',
      'PECOM',
      'PLANEJAMENTO',
      'SEG',
      'SOP',
      'TERRAPLANAGEM',
      'ANDAIME - MONTAGEM',
      'ANDAIME - DESMONTAGEM',
      'ANDAIME - AJUSTE',
      'CAMINHÃO CESTO',
      'CAMINHÃO MUNCK',
      'CAMINHÃO PIPA',
      'CAMINHÃO TANQUE DIESEL',
      'CAMINHÃO VÁCUO',
      'CARRETA TANQUE LIMPO 30M³',
      'CARRETA TANQUE SUJO 30M³',
      'CAVALO MUNCK + PRANCHA',
      'CIVIL',
      'EMPILHADEIRA',
      'GUINDASTE',
      'LAVAGEM INDUSTRIAL',
      'LIMPEZA',
      'PLATAFORMA ELEVATÓRIA (GIRAFINHA)',
      'POLIGUINDASTE'
    ].map(objectify)
  },
  {
    key: 'status',
    name: 'Status da PT',
    values: [
      'Requisição da PT',
      'Correção da PT',
      'Elaboração Emitente',
      'Elaboração Coemitente',
      'Validação Liderança',
      'Validação QSMS',
      'Emissão em Campo',
      'Aprovação Téc. Segurança',
      'Renovação Requisitante',
      'Renovação Emitente',
      'Execução do Serviço',
      'Quitação Emitente',
      'Quitação Coemitente',
      'Quitação Téc. Segurança',
      'Reprogramação da PT',
      'PT Cancelada',
      'PT Concluída',
      'Renovação da PT'
    ]
      .sort((a, b) => a.localeCompare(b))
      .map(objectify)
  }
];
