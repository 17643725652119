import { addHours } from 'date-fns';
import React from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { useParams } from '../../hooks/Common/useParams';

type DatepickerSearchParamsProps = {
  name: string;
  value: string | null;
  keyValue: string;
};

const DatepickerSearchParams: React.FC<DatepickerSearchParamsProps> = ({
  name,
  value,
  keyValue
}) => {
  const { setParam, params } = useParams();

  const handleChange = (val: any) => {
    if (!val?.startDate) {
      params.delete(keyValue);
      setParam(params);
    } else {
      params.set(keyValue, val?.startDate?.replaceAll('-', '/'));
      setParam(params);
    }
  };

  return (
    <div className="flex flex-col gap-1">
      <h3 className="text-sm font-bold text-white">{name}</h3>
      <Datepicker
        value={{
          startDate: value ? addHours(new Date(value), 3) : null,
          endDate: value ? addHours(new Date(value), 3) : null
        }}
        popoverDirection="down"
        asSingle={true}
        useRange={false}
        primaryColor={'sky'}
        onChange={handleChange}
        i18n="pt-br"
        placeholder="Selecione uma data"
        inputClassName={
          'relative transition-all duration-300 h-[36px] pl-2 pr-10 w-full border-[1px] border-[#cccccc] dark:border-slate-600 rounded-[10px] tracking-wide font-light text-sm text-white placeholder-white bg-primary focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-sky-500 stroke-white focus:ring-sky-500/20'
        }
        classNames={{
          toggleButton() {
            return 'absolute right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed stroke-white svg-white';
          }
        }}
      />
    </div>
  );
};

export default DatepickerSearchParams;
