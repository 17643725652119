import ReactDOM from 'react-dom';

interface Props {
  children: JSX.Element;
}

const FloatingPortal = ({ children }: Props) => {
  return ReactDOM.createPortal(children, document.body);
};

export default FloatingPortal;
