import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { KanbanColumn } from './KanbanColumn';
import KanbanModalColumn from './KanbanModalColumn';

export type PhaseData<T> = {
  cardsData: T[];
  totalCount: number;
};

interface Props<T, F> {
  title: string;
  cacheName: string;
  filters: F;
  getOperations: (page: number) => Promise<PhaseData<T> | undefined>;
  createCards: (data: T) => JSX.Element;
  createModalItens: (data: T) => JSX.Element;
  emptyMessage: string;
}

export function KanbanPhase<T, F>({
  title,
  cacheName,
  filters,
  getOperations,
  createCards,
  createModalItens,
  emptyMessage
}: Props<T, F>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cards, setCards] = useState<T[]>([]);
  const [page, setPage] = useState<number>(1);

  const handleGetOperations = async () => getOperations(page);

  const { data, isLoading, isFetching } = useQuery(
    [cacheName, title, page, filters],
    handleGetOperations,
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    setPage(1);
  }, [filters]);

  useEffect(() => {
    if (data && page === 1) {
      setCards(data.cardsData ?? []);
    } else if (data && !isFetching) {
      setCards(old => [...old, ...(data.cardsData ?? [])]);
    }
  }, [data, page, isFetching]);

  const handleLoadMore = () => setPage(old => old + 1);
  const totalCount = data?.totalCount ?? 0;

  const handleExpandList = () => {
    setIsOpen(old => !old);
  };

  return (
    <>
      <KanbanColumn
        isLoading={isLoading}
        isFetching={isFetching}
        totalCount={totalCount}
        handleLoadMore={handleLoadMore}
        handleExpandList={handleExpandList}
        title={title}
        emptyMessage={emptyMessage}
        dataLenght={cards.length}
      >
        {cards.map(createCards)}
      </KanbanColumn>

      {isOpen && (
        <KanbanModalColumn
          title={title}
          isOpen={isOpen}
          setIsOpen={() => setIsOpen(old => !old)}
          setLoadMore={handleLoadMore}
          isLoading={isLoading || isFetching}
          totalCount={data?.totalCount ?? 0}
          dataLenght={cards.length}
        >
          {cards.map(createModalItens)}
        </KanbanModalColumn>
      )}
    </>
  );
}
