import { InterventionStatus } from '../../components/molecules/InterventionPanelCard';
import { getOilWellAsync } from './AlertFilters';
import { objectify } from './WorflowTests';

export const InterventionPanelFilters = [
  {
    key: 'interventionStatus',
    name: 'Status da Intervenção',
    values: Object.values(InterventionStatus)
      .filter(value => typeof value === 'string')
      .map(objectify)
  },
  {
    key: 'oilwell',
    name: 'Código de Poço',
    values: [],
    asyncFn: getOilWellAsync
  }
];
