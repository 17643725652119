import React, { useEffect, useRef, useState } from 'react';
import { SlOptionsVertical } from 'react-icons/sl';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import FloatingPortal from './FloatingPortal';

interface Props {
  nameId: string;
  links: { label: string; url: string }[];
  buttonClassName?: string;
  menuClassName?: string;
}
// TODO: Criar um componente de menu de opções para ser utilizado em diversos lugares, genérico, poder
const MenuOptions: React.FC<Props> = ({
  nameId,
  links,
  buttonClassName,
  menuClassName
}) => {
  const [isMenuOptionsVisible, setMenuOptionsVisible] = useState(false);
  const toggleTooltip = () => {
    setMenuOptionsVisible(!isMenuOptionsVisible);
  };

  const closeMenu = () => {
    setMenuOptionsVisible(false);
  };

  const getPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      return {
        top: rect.top - 36,
        left: rect.left - 18
      };
    }

    return { top: 0, left: 0 };
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let handler = ({ target }: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current.contains(target as Node)) {
        closeMenu();
      }
    };

    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [buttonRef]);

  useEffect(() => {
    getPosition();
    window.addEventListener('scroll', closeMenu, true);

    return () => {
      window.removeEventListener('scroll', closeMenu, true);
    };
  }, [buttonRef]);

  return (
    <>
      <button
        ref={buttonRef}
        className={buttonClassName}
        onClick={toggleTooltip}
      >
        <SlOptionsVertical className="text-[#A2A2A2] hover:text-[#666] transition-colors" />
      </button>

      <FloatingPortal>
        <div
          style={getPosition()}
          className={twMerge(
            `
            fixed
            bg-white
            flex flex-col 
            p-2 
            shadow-light
            before:content-[''] before:absolute before:-left-1 before:top-8 before:w-4 before:h-4 before:bg-white before:rotate-45 before:-z-10
            rounded-lg
            z-50
            transition-opacity
            ${
              isMenuOptionsVisible
                ? 'opacity-100 visible'
                : 'opacity-0 invisible'
            }
      `,
            menuClassName
          )}
        >
          {links.map(it => (
            <Link
              key={`${nameId}-${it.label}`}
              to={it.url}
              className="flex items-center gap-6 py-2 px-3 w-[120px] hover:bg-btn-light rounded-md group transition-all"
            >
              <span className="text-sm text-gray group-hover:text-[#292929] transition-all">
                {it.label}
              </span>
            </Link>
          ))}
        </div>
      </FloatingPortal>
    </>
  );
};

export default MenuOptions;
