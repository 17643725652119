import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import Spinner from '../atoms/Spinner';

export enum CardsVariant {
  Summary = 'summary',
  ValueTagged = 'valueTagged',
  Criticity = 'criticity'
}

export enum CardsFontVariant {
  Big = 'big',
  Medium = 'medium',
  Small = 'small',
  XSmall = 'xsmall'
}

export enum Criticity {
  High = 'Alta',
  Medium = 'Média',
  Low = 'Baixa'
}

type CommonCardProps = {
  title: string;
  value: { label?: string; value?: string }[];
  priority?: 'high' | 'low' | 'medium';
  disabled?: boolean;
  font?: CardsFontVariant;
  className?: string;
};

type ValueTaggedCardsProps = CommonCardProps & {
  textColor: string;
  bgColor: string;
};

export type SummaryBigCardsItem =
  | (CommonCardProps & {
      variant?: CardsVariant.Summary | CardsVariant.Criticity | undefined;
    })
  | (ValueTaggedCardsProps & { variant: CardsVariant.ValueTagged });

interface SummaryBigCards {
  data: SummaryBigCardsItem[];
  isFetching?: boolean;
  wrapperClassName?: string;
}

type CardComponent = ({
  data,
  key,
  isFetching
}: {
  data: CommonCardProps;
  key: string;
  isFetching?: boolean;
}) => ReactNode;

export const SummaryBigCards = ({
  data,
  isFetching,
  wrapperClassName
}: SummaryBigCards) => {
  return (
    <div className={twMerge('flex gap-2 flex-wrap', wrapperClassName)}>
      {data.map((info, index) => {
        const key = info.title + index;
        switch (info.variant) {
          case CardsVariant.Criticity:
            return CriticityCard({ data: info, isFetching, key });
          case CardsVariant.ValueTagged:
            return ValueTaggedCard({ data: info, isFetching, key });
          case CardsVariant.Summary:
          case undefined:
            return SummaryCard({ data: info, isFetching, key });
        }
      })}
    </div>
  );
};

const SummaryCard = CardsTemplate(value => <>{value}</>);

const ValueTaggedCard = ({
  data,
  key,
  isFetching
}: {
  data: ValueTaggedCardsProps;
  key: string;
  isFetching?: boolean;
}) => {
  const getTagColors = (_value?: string) => {
    return `rounded px-2 py-1 text-xs ${data.bgColor} ${data.textColor}`;
  };
  return ValueTaggedCardTemplate(getTagColors)({ data, key, isFetching });
};

const CriticityCard = ValueTaggedCardTemplate(getCriticityColors);

function ValueTaggedCardTemplate(
  getTagColors: (value?: string) => string
): CardComponent {
  return CardsTemplate(value => {
    const tagColors = getTagColors(value);
    return <span className={tagColors}>{value}</span>;
  });
}

function CardsTemplate(
  getValueComponent: (value: string) => ReactNode
): CardComponent {
  return ({ data, key, isFetching }) => {
    const { fontTitle, fontLabels, fontValues } = getCardFont(data.font);
    const cardColors = getCardColors(data.priority, data.disabled);

    return (
      <div
        className={twMerge(
          'flex flex-col px-4 py-3 rounded flex-1 md:min-w-[20%] lg:min-w-[14%] min-w-[12rem]',
          data.value.length > 1
            ? 'flex-grow-[2] col-span-1'
            : 'flex-grow-[1] col-span-1',
          cardColors,
          data.className
        )}
        key={key}
      >
        <div className={twMerge('font-medium', fontTitle)}>{data.title}</div>

        <div className="flex gap-2">
          {data.value?.map((it, idx) => {
            return (
              <div
                className="flex flex-col w-full mt-auto"
                key={it.label || '' + idx}
              >
                {it.label ? (
                  <span className={twMerge('font-medium', fontLabels)}>
                    {it.label}
                  </span>
                ) : null}
                <span className={twMerge('font-bold', fontValues)}>
                  {isFetching ? (
                    <Spinner classNameWrapper="w-min" size={32} color="white" />
                  ) : data.disabled || it.value == 'NaN' || !it.value ? (
                    '-'
                  ) : (
                    getValueComponent(it.value)
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}

const getCardFont = (font?: CardsFontVariant) => {
  switch (font) {
    case CardsFontVariant.Medium:
      return {
        fontTitle: 'text-lg',
        fontLabels: 'text-xs',
        fontValues: 'text-lg'
      };
    case CardsFontVariant.Small:
      return {
        fontTitle: 'text-base',
        fontLabels: 'text-xs',
        fontValues: 'text-base'
      };
    case CardsFontVariant.XSmall:
      return {
        fontTitle: 'text-sm',
        fontLabels: 'text-xs',
        fontValues: 'text-sm'
      };
    case CardsFontVariant.Big:
    case undefined:
      return {
        fontTitle: 'text-xl',
        fontLabels: 'text-sm',
        fontValues: 'text-2xl'
      };
  }
};

const getCardColors = (bg?: 'high' | 'low' | 'medium', disabled = false) => {
  switch (bg) {
    case 'low':
      return 'bg-white text-primary border border-gray/50';
    case 'medium':
      return 'bg-[#D7DFFF] text-primary';
    case 'high':
    default:
      if (disabled) {
        return 'bg-gray text-white';
      }
      return 'bg-primary text-white';
  }
};

function getCriticityColors(value?: string): string {
  const tagFormat = 'rounded px-3 py-1 text-xs';
  switch (value) {
    case Criticity.High:
      return twMerge('bg-red/25 text-red', tagFormat);
    case Criticity.Medium:
      return twMerge('bg-[#FFF069]/50 text-[#AD953C]', tagFormat);
    case Criticity.Low:
      return twMerge('bg-[#10AE88]/25 text-[#0C8A6C]', tagFormat);
    default:
      return tagFormat;
  }
}
